var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { backgroundColor, border, borderColor, borderLeft, borderRight, color, fontSize, lineHeight, space, width, } from 'styled-system';
import { Button } from '../../common/components/Button';
import ButtonWithIcon from '../../common/components/Button/ButtonWithIcon';
import CountDown from '../../common/components/CountDown';
import { Flex } from '../../common/components/Flex';
import { font } from '../../common/components/RichText';
import { Text } from '../../common/components/Text';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { boxSizing, textDecoration } from '../../common/theme/system-utilities';
import { getWithAutoLogoutBanner, performLogout, saveWithAutoLogoutBanner, } from '../utils';
var INITIAL_MINUTES_LEFT = 2;
var MAX_MINUTES_TO_ADD = 5;
var MIN_MINUTES_TO_ADD = 1;
var LOGOUT_TIMER_DELAY = 1000;
var COLOR_SECONDARY_TEXT_STRONG = 'sys.secondary.text.strong';
var flexContainerStyle = {
    alignItems: ['center', 'flex-start', 'center'],
    flexDirection: ['column', null, 'row'],
};
var containerStyle = {
    border: '1px solid',
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: 'sys.secondary.border.default',
    bg: 'sys.secondary.background.weak',
};
var innerContainerStyle = __assign(__assign({}, flexContainerStyle), { justifyContent: 'space-between', maxWidth: '1600px', width: 1, boxSizing: 'border-box', py: 'sp_16', px: ['sp_16', 'sp_24', 'sp_48', 'sp_64'], m: '0 auto' });
var textFlexContainerStyle = __assign(__assign({}, flexContainerStyle), { flexDirection: ['column', null, null, 'row'], alignItems: ['flex-start', null, null, 'center'] });
var ctaFlexContainerStyle = {
    width: [1, null, 'auto'],
    flexDirection: ['column', 'row'],
    alignItems: 'center',
    mt: ['sp_16', null, 'sp_0'],
};
var textStyle = {
    color: COLOR_SECONDARY_TEXT_STRONG,
    fontSize: ['fs_14', null, null, 'fs_16'],
    lineHeight: ['lh_20', null, null, 'lh_24'],
    mr: 'sp_8',
};
var titleStyle = __assign(__assign({}, textStyle), { fontWeight: 'bold', fontSize: ['fs_16', null, null, 'fs_20'], lineHeight: ['lh_20', null, null, 'lh_28'], mr: ['sp_0', null, null, 'sp_16'], mb: ['sp_8', null, null, 'sp_0'] });
var linkButtonStyle = {
    color: COLOR_SECONDARY_TEXT_STRONG,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    textDecoration: 'underline',
    ml: ['sp_0', null, null, 'sp_16'],
    pl: 'sp_32',
    pr: ['sp_32', 'sp_0'],
};
var buttonWithIconStyle = {
    width: [1, 'auto'],
    mb: ['sp_16', 'sp_0'],
    color: 'sys.neutral.text.default',
};
var Container = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), backgroundColor, border, borderColor, borderLeft, borderRight);
var InnerContainer = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), boxSizing);
var StyledLinkButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, space, fontSize, lineHeight, textDecoration);
var StyledCountDown = styled(CountDown)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  & > div {\n    margin-left: 0;\n    padding-top: 0;\n    ", ";\n  }\n"], ["\n  & > div {\n    margin-left: 0;\n    padding-top: 0;\n    ", ";\n  }\n"])), font(['fs_16', null, null, 'fs_18'], ['lh_20', null, null, 'lh_24']));
var StyledButtonWithIcon = styled(ButtonWithIcon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n\n  & svg {\n    stroke: none;\n  }\n"], ["\n  ", ";\n  ", ";\n\n  & svg {\n    stroke: none;\n  }\n"])), width, color);
var AutoLogoutBanner = function (_a) {
    var _b = _a.isShown, isShown = _b === void 0 ? false : _b;
    var _c = useLocalization(), t = _c.t, prefix = _c.prefix;
    var showBanner = isShown || getWithAutoLogoutBanner();
    var redirectUrl = prefix('/');
    var _d = __read(useState(0), 2), minutesAdded = _d[0], setMinutesAdded = _d[1];
    var _e = __read(useState(dayjs().add(INITIAL_MINUTES_LEFT, 'minute')), 2), timeLeft = _e[0], setTimeLeft = _e[1];
    useEffect(function () {
        if (isShown && !getWithAutoLogoutBanner()) {
            saveWithAutoLogoutBanner();
        }
    }, [isShown]);
    useEffect(function () {
        if (showBanner) {
            var logoutTimer_1 = setInterval(function () {
                var currentTime = dayjs();
                if (timeLeft.isBefore(currentTime)) {
                    performLogout(redirectUrl);
                    clearInterval(logoutTimer_1);
                }
            }, LOGOUT_TIMER_DELAY);
            return function () { return clearInterval(logoutTimer_1); };
        }
    }, [showBanner, timeLeft]);
    var handleExtendTimeLeftButtonClick = function () {
        setMinutesAdded(minutesAdded + 1);
        setTimeLeft(timeLeft.add(MIN_MINUTES_TO_ADD, 'minute'));
    };
    var handleLogoutNowButtonClick = function () {
        performLogout(redirectUrl);
    };
    if (!showBanner) {
        return null;
    }
    return (React.createElement(Container, __assign({}, containerStyle),
        React.createElement(InnerContainer, __assign({}, innerContainerStyle),
            React.createElement(Flex, __assign({}, textFlexContainerStyle),
                React.createElement(Text, __assign({}, titleStyle), t('h24_auto_logout_banner_title')),
                React.createElement(Flex, { flexWrap: ['wrap', 'nowrap'] },
                    React.createElement(Text, __assign({}, textStyle), t('h24_auto_logout_banner_subtitle')),
                    React.createElement(StyledCountDown, { color: COLOR_SECONDARY_TEXT_STRONG, endDate: dayjs(timeLeft).toJSON() }))),
            React.createElement(Flex, __assign({}, ctaFlexContainerStyle),
                React.createElement(StyledButtonWithIcon, __assign({}, buttonWithIconStyle, { iconName: "plusStrong", onClick: handleExtendTimeLeftButtonClick, disabled: minutesAdded === MAX_MINUTES_TO_ADD }), t('h24_extend_logout_timer')),
                React.createElement(StyledLinkButton, __assign({}, linkButtonStyle, { kind: "bare", onClick: handleLogoutNowButtonClick }), t('h24_logout_now'))))));
};
export default AutoLogoutBanner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
