var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { alignItems, display, fontSize, height, lineHeight, size, space, width, } from 'styled-system';
import { fill, flexShrink, stroke, themeHeight } from '../../theme/system-utilities';
import Icon from '../Icon';
import { Button } from './index';
export var DEFAULT_ICON_SIZE = 28;
var ButtonWithIconWrapper = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, width, height, display, fontSize, lineHeight, alignItems, themeHeight);
ButtonWithIconWrapper.defaultProps = __assign(__assign({}, Button.defaultProps), { kind: 'secondary', display: 'flex', alignItems: 'center' });
var ButtonIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, size, flexShrink, fill, stroke);
ButtonIcon.defaultProps = {
    mr: ['sp_0', 'sp_4'],
    flexShrink: 0,
};
var getIconColor = function (kind) {
    if (kind === 'primary') {
        return 'sys.neutral.icon.inverted';
    }
    return 'sys.neutral.icon.strong';
};
// eslint-disable-next-line react/display-name
var ButtonWithIcon = forwardRef(function (_a, ref) {
    var _b = _a.iconSize, iconSize = _b === void 0 ? DEFAULT_ICON_SIZE : _b, iconName = _a.iconName, iconGap = _a.iconGap, children = _a.children, props = __rest(_a, ["iconSize", "iconName", "iconGap", "children"]);
    var iconColor = getIconColor(props.kind);
    return (React.createElement(ButtonWithIconWrapper, __assign({}, props, { ref: ref }),
        iconName && (React.createElement(ButtonIcon, { name: iconName, size: iconSize, fill: iconColor, stroke: iconColor, mr: iconGap })),
        React.createElement("span", null, children)));
});
export default ButtonWithIcon;
var templateObject_1, templateObject_2;
