var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { alignItems, color, display, flexWrap, fontWeight, justifyContent, letterSpacing, lineHeight, maxWidth, minWidth, space, } from 'styled-system';
import { Text } from './Text';
// To get the difference in days-hours-minutes-seconds between today and a given date,
// considering the midnight as deadline.
var calculateTimeLeft = function (endDate) {
    var now = dayjs();
    var deadline = dayjs(endDate);
    if (deadline.isBefore(now)) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0, isOver: true };
    }
    var seconds = dayjs(deadline).diff(now, 'second');
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    return {
        days: days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60,
        isOver: false,
    };
};
var addLeadingZero = function (value) { return (value < 10 ? "0".concat(value) : value); };
var formatTimeLeft = function (_a) {
    var days = _a.days, hours = _a.hours, minutes = _a.minutes, seconds = _a.seconds;
    return [days, hours, minutes, seconds].map(addLeadingZero).join(':');
};
/**
 * Date string ending with colon and four digits right after
 * seems to be inexplicable for Safari and IE11
 */
var sanitizeDate = function (date) { return date.replace('+0000', '+00:00'); };
var CountDownContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), lineHeight, display, flexWrap, alignItems, justifyContent, space);
CountDownContainer.defaultProps = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    lineHeight: [1, 3],
};
var CountDownLabel = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  ", ";\n  ", ";\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])), maxWidth, lineHeight);
CountDownLabel.defaultProps = {
    as: 'p',
    maxWidth: '147px',
    my: 'sp_0',
};
var Timer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), fontWeight, letterSpacing, space, minWidth, color);
Timer.defaultProps = {
    fontWeight: 'bold',
    letterSpacing: '2px',
    ml: '10px',
    pt: 'sp_2',
    minWidth: '110px',
};
var TIMER_INTERVAL = 1000;
var useTimeLeft = function (endDate) {
    var sanitizedEndDate = useMemo(function () { return sanitizeDate(endDate); }, [endDate]);
    var _a = __read(useState(null), 2), timeLeft = _a[0], setTimeLeft = _a[1];
    useEffect(function () {
        setTimeLeft(calculateTimeLeft(sanitizedEndDate));
        var timer = setInterval(function () {
            var newTimeLeft = calculateTimeLeft(sanitizedEndDate);
            setTimeLeft(newTimeLeft);
            if (newTimeLeft.isOver) {
                clearInterval(timer);
                timer = null;
            }
        }, TIMER_INTERVAL);
        return function () {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [endDate]);
    return timeLeft;
};
var CountDown = function (_a) {
    var label = _a.label, endDate = _a.endDate, className = _a.className, textColor = _a.color, styleProps = __rest(_a, ["label", "endDate", "className", "color"]);
    var timeLeft = useTimeLeft(endDate);
    return timeLeft ? (React.createElement(CountDownContainer, { "data-testid": "countdown-container", className: className, lineHeight: styleProps === null || styleProps === void 0 ? void 0 : styleProps.lineHeight },
        label && (React.createElement(CountDownLabel, { "data-testid": "count-down-label", color: textColor }, label)),
        React.createElement(Timer, { "data-testid": "count-down-timer", color: textColor, pt: styleProps === null || styleProps === void 0 ? void 0 : styleProps.pt }, formatTimeLeft(timeLeft)))) : null;
};
export default CountDown;
var templateObject_1, templateObject_2, templateObject_3;
